import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logoutUser } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchApps, changeApp } from "../../store/actions/appActions";

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentApp = useSelector((state) => state.app.currentApp);
  const app = useSelector((state) => state.app);
  useEffect(() => {
    dispatch(fetchApps());
  }, [currentApp]);

  const auth = useSelector((state) => state.auth);
  if (!auth.isAuthenticated) {
    return null;
  }

  const onChangeApp = (e) => {
    const id = e.target.value;
    dispatch(changeApp(id));
    history.push("/");
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };
  const filterNames = ['ClickAgencyAI', 'AIstaffs', 'DoitAll', 'FastPaid', 'VooDuu', 'AIsocial', 'AISellers', 'AIReputors', 'AiDuals', "DoitAll"]
  return (
    <div>
      <section>
        <div className="navbar-bg" />
        <nav className="navbar navbar-expand-lg main-navbar">
          <div className="form-inline mr-auto">
            <ul className="navbar-nav mr-3">
              <li>
                <Link
                  to="#"
                  data-toggle="sidebar"
                  className="nav-link nav-link-lg collapse-btn"
                >
                  <i className="fas fa-bars" />
                </Link>
              </li>
              <li>
                <Link to="#" className="nav-link nav-link-lg fullscreen-btn">
                  <i className="fas fa-expand" />
                </Link>
              </li>
              {app.apps ? (
                <li>
                  <select
                    className="browser-default custom-select pl-2"
                    value={currentApp.id}
                    onChange={(e) => onChangeApp(e)}
                  >
                    {app.apps.filter((currVal) => {
                      return (
                        !filterNames.includes(currVal.name)
                      )
                    }).map((item) => {
                      if (item.status === "1") {
                        return (
                          <option key={item.id} value={item.id}>
                            {" "}
                            {item.name}
                          </option>
                        );
                      }
                    })}
                  </select>
                </li>
              ) : (
                <li>
                  <i className="fa fa-spinner fa-spin ml-3" />
                </li>
              )}
            </ul>
          </div>
          <ul className="navbar-nav navbar-right">
            <li className="dropdown">
              <Link
                to="#"
                data-toggle="dropdown"
                className="nav-link dropdown-toggle nav-link-lg nav-link-user"
              >
                <img
                  alt="image"
                  src={auth.user.avatar}
                  className="user-img-radious-style"
                />
                <span className="d-sm-none d-lg-inline-block" />
              </Link>
              <div className="profileMenu">
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-title">Hello {auth.user.name}</div>
                  <Link to="/profile" className="dropdown-item has-icon">
                    <i className="far fa-user" /> Profile
                  </Link>
                  <Link to="timeline.html" className="dropdown-item has-icon">
                    <i className="fas fa-bolt" /> Activities
                  </Link>
                  <Link to="#" className="dropdown-item has-icon">
                    <i className="fas fa-cog" /> Settings
                  </Link>
                  <div className="dropdown-divider" />
                  <Link
                    onClick={(e) => logout(e)}
                    className="dropdown-item has-icon text-danger"
                  >
                    <i className="fas fa-sign-out-alt" /> Logout
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default Navbar;
