import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "../../store/actions/reelAppsActions";
import axios from "axios";

const ChangeDetails = ({ user, setRender }) => {

    const dispatch = useDispatch();
    const appName = useSelector(state => state.app.currentApp.name);
    const auth = useSelector(state => state.auth);
    const currentApp = useSelector(state => state.app.currentApp);
    const memberships = useSelector(state => state.reelapps.memberships || []);
    const [loader, setLoader] = useState({
        loader: false,
        buttonText: 'Save Changes'
    });
    const [details, setDetails] = useState({
        secret_key: useSelector(state => state.app.currentApp.secret_key),
        id: user?.user_id ? user?.user_id : user?.id,
        name: user?.name ? user?.name : user?.first_name,
        user_type: user?.user_type ? user?.user_type : user?.type ? user?.type : user?.account_type ? user?.account_type : "is_trial",
        account_type: user?.account_type,
        membership: user?.type ? user?.type?.split('__').filter(val => val) || [] : user?.acount_type?.split('__').filter(val => val) || []
    });
    const OnInputChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    };

    const getSelectValue = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });

    };
    const memLevel = (e) => {
        let array = details.membership;

        if (array.includes(e.target.value)) {
            array.splice(array.indexOf(e.target.value), 1);
        } else {
            array.push(e.target.value);
        }
        setDetails({ ...details, membership: array });
    };

    const [isBcast, setIsBcast] = useState(user?.is_bcast);
    const [isBcontent, setIsBcontent] = useState(user?.is_bcontent);

    const OnChangeMem = (e) => {

        if (e.target.value === 'uoriweoiuweqognkqw' && isBcast == 0) {

            axios({
                method: 'POST',
                url: `https://bvoice.reelapps.io/api/create-app-user`,
                data: { email: user.email, token: e.target.value, url: 'abc', secret_key: 'reyewroinuiyqw' },
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token }
            }).then(res => {
                if (res.data.status === true) {
                    setIsBcast('1');
                    dispatch({ type: 'SUCCESS', payload: res.data.message });
                } else {
                    setIsBcast('0');
                    dispatch({ type: 'ERROR', payload: res.data.message });
                }
            }).catch(error => {
                setIsBcast('0');
            })

        } else if (e.target.value === 'qwiu23jnuyjljieqpo' && isBcontent == 0) {

            axios({
                method: 'POST',
                url: `https://bvoice.reelapps.io/api/create-app-user`,
                data: { email: user?.email, token: e.target.value, url: 'ac', secret_key: 'reyewroinuiyqw' },
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.token }
            }).then(res => {
                if (res.data.status === true) {
                    setIsBcontent('1');
                    dispatch({ type: 'SUCCESS', payload: res.data.message });
                } else {
                    setIsBcontent('0');
                    dispatch({ type: 'ERROR', payload: res.data.message });
                }
            }).catch(error => {
                setIsBcontent('0');
            })
        }

    };


    const changeValue = (val) => {
        if (/^\d+$/.test(val)) {
            return val.toString();
        }
        switch (val) {
            case "is_super_admin":
                return "1"
            case "is_user":
                return "2"
            case "is_virtual_account":
                return "3"
            case "is_client_account":
                return "4"
            case "is_trial":
                return "is_trial"
            default:
                return "is_trial"
        }
    }
    const onSubmitForm = (e) => {
        e.preventDefault();
        if (details.membership.length > 0 || memberships.length <= 0) {
            setLoader({
                loader: true,
                buttonText: "Updating..."
            });
            dispatch(updateDetails(details, setLoader, setRender));
        }
        else {
            dispatch({ type: 'ERROR', payload: "Please select membership" });
        }

    };
    return (
        <form onSubmit={(e) => onSubmitForm(e)}>
            <div className="row">
                <div className="form-group col-md-12 col-12 text-left">
                    <label>Name</label>
                    <div className="input-group mb-3">
                        <input type="text" onChange={(e) => OnInputChange(e)} name="name" className="form-control"
                            defaultValue={user?.name ? user?.name : user?.first_name} required />
                    </div>

                    {
                        appName === 'bVoice' ?
                            <div className="row">
                                <div className="col-md-6">
                                    <label>bCast</label>
                                    <div className="input-group mb-3">
                                        <input type="checkbox" checked={isBcast.includes(1)} id="memCast" className="mr-1" value="uoriweoiuweqognkqw" onChange={(e) => OnChangeMem(e)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label>bContent</label>
                                    <div className="input-group mb-3">
                                        <input type="checkbox" checked={isBcontent.includes(1)} id="memCast" className="mr-1" value="qwiu23jnuyjljieqpo" onChange={(e) => OnChangeMem(e)} />
                                    </div>
                                </div>
                            </div>
                            : ''
                    }

                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-6 col-md-12 mb-1">
                    {memberships?.length > 0 ?
                        <fieldset className="form-group text-left">

                            <label data-toggle="collapse" data-target="#memlevel1" >Membership Levels <i className="pl-2 fa fa-sort-down" /></label>

                            <br />
                            <div className="collapse" id="memlevel1">


                                {
                                    currentApp.name === 'AIsocial' ?

                                        memberships?.map((mem_level, index) => {

                                            return (
                                                <React.Fragment key={index}>

                                                    <input type="checkbox"
                                                        key={mem_level.name ? mem_level.name : mem_level.title}
                                                        className="mr-1"
                                                        checked={details.membership.includes(mem_level.name ? mem_level.name : mem_level.title)}
                                                        onChange={(e) => memLevel(e)}
                                                        defaultValue={mem_level.name ? mem_level.name : mem_level.title}

                                                    />
                                                    {mem_level.title === "Premium" ? "Elite" : mem_level.title}
                                                    <br />

                                                </React.Fragment>
                                            );
                                        }) : memberships?.map((mem_level, index) => {
                                            return (
                                                <React.Fragment key={index}>

                                                    <input type="checkbox"
                                                        key={mem_level.name ? mem_level.name : mem_level.title}
                                                        className="mr-1"
                                                        checked={details.membership.includes(mem_level.name ? mem_level.name : mem_level.title)}
                                                        onChange={(e) => memLevel(e)}
                                                        defaultValue={mem_level.name ? mem_level.name : mem_level.title}

                                                    />
                                                    {mem_level.name ? mem_level.name : mem_level.title}
                                                    <br />

                                                </React.Fragment>
                                            );
                                        })
                                }
                            </div>

                        </fieldset> : ""
                    }

                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                    <fieldset className="form-group text-left">
                        <label htmlFor="helperText">User Type:</label>
                        <select id="select" value={changeValue(details.user_type ? details.user_type : details.account_type)} name="user_type" className="form-control" required onChange={(e) => getSelectValue(e)}>
                            <option value="1">Super Admin</option>
                            <option value="2">User</option>
                            <option value="3">Virtual</option>
                            <option value="4">client</option>
                            <option value="is_trial" selected={details.user_type === "is_trial"}>Trial</option>
                        </select>
                    </fieldset>
                </div>
            </div>
            <div className="card-footer text-right d-flex justify-content-end pb-2 pt-2 pr-3"   >
                <button className="btn btn-primary" type="submit">
                    {loader.loader ? <i className="fa fa-spinner fa-spin mr-1" /> : ''}{loader.buttonText}
                </button>
            </div>
        </form>
    )
}

export default ChangeDetails;
